import { Google } from '@/lib/analytics/google'
import { Posthog } from '@/lib/analytics/posthog'
import * as React from 'react'
import { ClientOnly } from 'remix-utils/client-only'

export * as Analytics from '.'
export { CookieBanner } from './cookie-banner'

export type Event =
  | { action: 'sign_up' }
  | { action: 'uploaded_file'; type: string; size: number }
  | { action: 'cancelled_upload' }
  | { action: 'deleted_file' }
  | { action: 'created_page' }

export function capture(event: Event, options?: { once?: boolean }) {
  const alreadyRan = sessionStorage.getItem(event.action)

  if (alreadyRan && options?.once) return
  if (options?.once) sessionStorage.setItem(event.action, 'once')

  Google.capture(event)
  Posthog.capture(event)
  console.debug(event)
}

export function Bootstrap() {
  React.useEffect(() => {
    Posthog.init()
  }, [])

  return null
}

export function useTrackPageview() {
  Posthog.useTrackPageview()
}

export function useIdentifiedUser(
  user?: {
    id: string
    email: string
    firstName?: string | null
    lastName?: string | null
  } | null,
) {
  Google.useIdentifiedUser(user)
  Posthog.useIdentifiedUser(user)
}

export function reset() {
  Posthog.reset()
  sessionStorage.clear()
}

export function Scripts() {
  if (!Google.tagId) return null

  return (
    <>
      <Google.Scripts />

      {/* Facebook */}
      <ClientOnly>
        {() => (
          <script
            async
            // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
            dangerouslySetInnerHTML={{
              __html: `
            (function(f, b, e, v, n, t, s) {
              if (f.fbq) return;
              n = f.fbq = function() {
                  n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
              };

              if (!f._fbq) f._fbq = n;
              n.push = n;
              n.loaded = !0;
              n.version = '2.0';

              n.queue = [];
              t = b.createElement(e);
              t.async = !0;

              t.src = v;
              s = b.getElementsByTagName(e)[0];

              s.parentNode.insertBefore(t, s)
          })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

          fbq('init', '1381346452783594');

          fbq('track', 'PageView');`,
            }}
          />
        )}
      </ClientOnly>

      {/* TikTok */}
      <ClientOnly>
        {() => (
          <script
            async
            // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
            dangerouslySetInnerHTML={{
              __html: `
          !function(w, d, t) {
            w.TiktokAnalyticsObject = t;
            var ttq = w[t] = w[t] || [];
            ttq.methods = ["page", "track", "identify", "instances", "debug", "on", "off", "once", "ready", "alias", "group", "enableCookie", "disableCookie", "holdConsent", "revokeConsent", "grantConsent"], ttq.setAndDefer = function(t, e) {
                t[e] = function() {
                    t.push([e].concat(Array.prototype.slice.call(arguments, 0)))
                }
            };
            for (var i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i]);
            ttq.instance = function(t) {
                for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++) ttq.setAndDefer(e, ttq.methods[n]);
                return e
            }, ttq.load = function(e, n) {
                var r = "https://analytics.tiktok.com/i18n/pixel/events.js",
                    o = n && n.partner;
                ttq._i = ttq._i || {}, ttq._i[e] = [], ttq._i[e]._u = r, ttq._t = ttq._t || {}, ttq._t[e] = +new Date, ttq._o = ttq._o || {}, ttq._o[e] = n || {};
                n = document.createElement("script");
                n.type = "text/javascript", n.async = !0, n.src = r + "?sdkid=" + e + "&lib=" + t;
                e = document.getElementsByTagName("script")[0];
                e.parentNode.insertBefore(n, e)
            };
            ttq.load('CKNUF93C77U81CKC3EDG');
            ttq.page();
          }(window, document, 'ttq');
          `,
            }}
          />
        )}
      </ClientOnly>
    </>
  )
}
