import type { Event } from '@/lib/analytics'
import type { CookieConsent } from '@/lib/analytics/cookie-banner'
import { useLocation } from '@remix-run/react'
import * as React from 'react'

export * as Google from './google'

declare global {
  interface Window {
    gtag: (
      option: string,
      gaTrackingId: string,
      options: Record<string, unknown>,
    ) => void
  }
}

export const tagId = import.meta.env.VITE_GOOGLE_TAG_ID

export function capture({ action, ...properties }: Event) {
  if (!window?.gtag) {
    console.warn(
      'window.gtag is not defined. This could mean your google analytics script has not loaded on the page yet.',
    )
    return
  }

  try {
    window.gtag('event', action, properties)
  } catch (err) {}
}

export function setConsent(consent: CookieConsent) {
  if (!window?.gtag || consent === 'undecided') return

  const newConsent = consent === 'yes' ? 'granted' : 'denied'
  window.gtag('consent', 'update', {
    ad_storage: newConsent,
    ad_user_data: newConsent,
    ad_personalization: newConsent,
    analytics_storage: newConsent,
  })
}

export function useIdentifiedUser(
  user?: {
    id: string
    email: string
    firstName?: string | null
    lastName?: string | null
  } | null,
) {
  const location = useLocation()

  React.useEffect(() => {
    if (!tagId) return
    if (!user) return

    window.gtag('config', tagId, {
      user_id: user.id,
      page_path: location.pathname,
    })
  }, [user, location.pathname])
}

export function Scripts() {
  if (!tagId) return null

  return (
    <>
      <script
        async
        // biome-ignore lint/security/noDangerouslySetInnerHtml: danger is my inner name
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}

          gtag('consent', 'default', {
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'analytics_storage': 'denied'
          });

          gtag('set', 'url_passthrough', false);
        `,
        }}
      />
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${tagId}`}
      />
      <script
        async
        id='gtag-init'
        // biome-ignore lint/security/noDangerouslySetInnerHtml: danger is my inner name
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${tagId}', {
            page_path: window.location.pathname,
          });
        `,
        }}
      />
    </>
  )
}
