import type { Event } from '@/lib/analytics'
import type { CookieConsent } from '@/lib/analytics/cookie-banner'
import { useLocation } from '@remix-run/react'
import posthog from 'posthog-js'
import * as React from 'react'

export * as Posthog from './posthog'

export function init() {
  posthog.init(import.meta.env.VITE_POSTHOG_API_KEY, {
    api_host: import.meta.env.VITE_TELEMETRY_URL,
    person_profiles: 'identified_only',
    persistence: 'memory',
    disable_session_recording: import.meta.env.VITE_STAGE !== 'production',
  })
}

export const reset = () => posthog.reset()

export function setConsent(consent: CookieConsent) {
  posthog.set_config({
    persistence: consent === 'yes' ? 'localStorage+cookie' : 'memory',
  })
}

export function useTrackPageview() {
  const location = useLocation()

  // biome-ignore lint/correctness/useExhaustiveDependencies: we want to trigger this when the location changes
  React.useEffect(() => {
    posthog.capture('$pageview')
  }, [location])
}

export function useIdentifiedUser(
  user?: {
    id: string
    email: string
    firstName?: string | null
    lastName?: string | null
  } | null,
) {
  React.useEffect(() => {
    if (!user) return
    posthog.identify(user.id, {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
    })
    posthog.set_config({ persistence: 'localStorage+cookie' })
  }, [user])
}

export function capture({ action, ...properties }: Event) {
  try {
    posthog.capture(action, properties)
  } catch (err) {}
}
